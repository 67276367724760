// Packages
import React from "react";
import { IonPage } from "@ionic/react";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";

class Privacy extends React.Component<RouteComponentProps> {
  render() {
    return (
      <IonPage className="ion-padding scroll-page">
        <div className="privacy-term-page">
          <h1 className="center-text bold-text">Privacy Policy</h1>
          <p>
            <strong>Effective Date:</strong> 01/27/25
          </p>
          <p>
            Goalden Hour (“we,” “us,” or “our”) is committed to protecting the
            privacy of our users (“you,” “your”). This Privacy Policy outlines
            how we collect, use, and safeguard your personal information when
            you use our platform, including the Goalden Hour app, Goal Post
            platform, website, and related services (collectively, the
            “Services”). By accessing or using our Services, you agree to the
            terms of this Privacy Policy.
          </p>
          <p>
            This Privacy Policy applies to all users of{" "}
            <a
              href="https://goaldenhour.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://goaldenhour.com/
            </a>{" "}
            and its associated Goalden Hour website, subdomains, mobile
            versions, and any associated applications, including the Goalden
            Hour app, Goal Post platform, and services operated by Goalden Hour,
            Inc. This policy also contains provisions for users in the European
            Union/European Economic Area (EU/EEA) and California residents, as
            required under the GDPR and CCPA/CPRA.
          </p>
          <hr />
          <h5 className="font-bold">1. Information We Collect</h5>
          <h6 className="font-bold">1.1 Information You Provide to Us</h6>
          <p>
            <ul>
              <li>
                <strong>Account Information:</strong> When you create an account
                with us, we collect your name, email address, and password.
              </li>
              <li>
                <strong>Profile Information:</strong> Goals, tasks, routines,
                reflections, and preferences you add to your profile.
              </li>
              <li>
                <strong>Payment Information:</strong> Billing address and
                payment details for subscription plans or purchases.
              </li>
            </ul>
          </p>
          <h6 className="font-bold">
            1.2 Information We Collect Automatically
          </h6>
          <p>
            <ul>
              <li>
                <strong>Usage Data:</strong> Details about how you use our
                Services, including interactions, time spent, and feature
                engagement.
              </li>
              <li>
                <strong>Device Information:</strong> Data about your device,
                such as IP address, browser type, operating system, and device
                identifiers.
              </li>
              <li>
                <strong>Cookies and Tracking:</strong> Technologies (e.g.,
                cookies, web beacons) to collect data on usage patterns and
                preferences.
              </li>
            </ul>
          </p>
          <h6 className="font-bold">1.3 Information from Third Parties</h6>
          <p>
            <ul>
              <li>
                <strong>Integrations:</strong> Data from connected third-party
                apps or platforms (e.g., calendars or productivity tools).
              </li>
              <li>
                <strong>Analytics and Advertising Partners:</strong> Aggregated
                insights about usage patterns or preferences.
              </li>
            </ul>
          </p>
          <hr />
          <h5 className="font-bold">2. How We Use Your Information</h5>
          <p>
            We use your information to:
            <ol>
              <li>Provide, personalize, and improve the Services </li>
              <li>Help you set, track, and achieve your goals </li>
              <li>Facilitate payments and manage subscriptions</li>
              <li>
                Communicate updates, recommendations, or customer support
                responses
              </li>
              <li>Improve functionality and security of the Services</li>
              <li>Analyze usage patterns to enhance user experience </li>
              <li>Comply with legal and regulatory obligations</li>
            </ol>
          </p>
          <hr />
          <h5 className="font-bold">3. How We Share Your Information</h5>
          <p>
            We may share your information with:
            <ol>
              <li>
                <strong>Service Providers:</strong> Trusted third parties that
                perform services on our behalf (e.g., payment processors,
                hosting providers).
              </li>
              <li>
                <strong>Coaches and Teams (Goal Post):</strong> Relevant
                goal-related data may be shared with coaches or teams at your
                request or as part of your usage of our Goal Post platform.
              </li>
              <li>
                <strong>Legal Compliance:</strong> Authorities if required to
                comply with laws or protect our rights.
              </li>
              <li>
                <strong>Business Transfers:</strong> In the event of a merger,
                acquisition, or sale of assets. We will notify you if your
                information becomes subject to a different privacy policy.
              </li>
            </ol>
          </p>
          <hr />
          <h5 className="font-bold">4. Your Privacy Choices</h5>
          <p>
            <ol>
              <li>
                <strong>Account Settings:</strong> Update or delete your
                account, profile, or communication preferences.
              </li>
              <li>
                <strong>Marketing Communications:</strong> Opt out of marketing
                emails or notifications.
              </li>
              <li>
                <strong>Cookies and Tracking:</strong> Manage preferences for
                cookies and tracking technologies.
              </li>
              <li>
                <strong>Delete Your Account:</strong> Contact us at{" "}
                <a
                  href="mailto:support@goaldenhour.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@goaldenhour.com
                </a>{" "}
                to permanently delete your account (subject to any applicable
                legal retention requirements).
              </li>
            </ol>
          </p>
          <hr />
          <h5 className="font-bold">5. Data Security</h5>
          <p>
            We implement industry-standard security measures, such as encryption
            and firewalls, to protect your information. However, no method of
            transmission over the internet is entirely secure. We recommend you
            take precautions to safeguard your account, such as using a strong
            password and keeping it confidential.
          </p>
          <hr />
          <h5 className="font-bold">6. Data Retention</h5>
          <p>
            We retain your information for as long as necessary to provide the
            Services and fulfill the purposes described in this Privacy Policy,
            unless a longer retention period is required or permitted by law.
            You may request the deletion of your data at any time by contacting
            us, and we will make reasonable efforts to comply, subject to legal
            or contractual obligations.
          </p>
          <hr />
          <h5 className="font-bold">7. Children’s Privacy</h5>
          <p>
            Goalden Hour is not intended for individuals under 13 years of age.
            We do not knowingly collect personal information from children. If
            we become aware of such data collection, we will delete it promptly.
            If you believe we may have collected information from a child under
            13, please contact us immediately.
          </p>
          <hr />
          <h5 className="font-bold">8. International Users</h5>
          <p>
            If you are accessing our Services from outside the United States,
            your information may be transferred, stored, and processed in the
            U.S. or other countries where we operate. We take steps to ensure
            that your data is protected in accordance with this Privacy Policy,
            including relying on appropriate transfer mechanisms such as
            Standard Contractual Clauses (SCCs) when required.
          </p>
          <hr />
          <h5 className="font-bold">
            9. Additional Disclosures for EU/EEA Users (GDPR)
          </h5>
          <h6 className="font-bold">9.1 Data Controller</h6>
          <p>
            For the purpose of the General Data Protection Regulation (GDPR),
            the data controller of your personal data is:
          </p>
          <code>
            Goalden Hour, Inc.
            <br />
            5407 NW Crady Lane,
            <br />
            Portland, OR 97229
            <br />
            Email: support@goaldenhour.com
          </code>
          <h6 className="font-bold">9.2 Legal Bases for Processing</h6>
          <p>
            We process your personal data under the following legal bases,
            depending on the context of the interaction and the type of personal
            data involved:
            <ol>
              <li>
                <strong>Consent (Article 6(1)(a) GDPR):</strong> Where you have
                given us explicit consent to process your information for a
                specific purpose (e.g., to receive marketing emails).
              </li>
              <li>
                <strong>Contract (Article 6(1)(b) GDPR):</strong> Where
                processing is necessary for the performance of a contract with
                you (e.g., to provide our Services or support) or to take steps
                at your request before entering into a contract.
              </li>
              <li>
                <strong>Legitimate Interests (Article 6(1)(f) GDPR):</strong>{" "}
                Where processing is necessary for the purposes of our legitimate
                interests or those of a third party, and not overridden by your
                rights or interests (e.g., to analyze usage and improve our
                Services).
              </li>
              <li>
                <strong>Legal Obligation (Article 6(1)(c) GDPR):</strong> Where
                we need to comply with a legal or regulatory obligation.
              </li>
            </ol>
          </p>
          <h6 className="font-bold">9.3 Your Rights Under the GDPR</h6>
          <p>
            EU/EEA users have the following rights, subject to limitations and
            exceptions under applicable law:
            <ul>
              <li>
                <strong>Right of Access:</strong> You can request confirmation
                about whether your personal data is processed and obtain a copy
                of that data.
              </li>
              <li>
                <strong>Right to Rectification:</strong> You can ask us to
                update or correct your personal information if it is inaccurate
                or incomplete.
              </li>
              <li>
                <strong>Right to Erasure (“Right to be Forgotten”):</strong> You
                can request deletion of your personal data where there is no
                compelling reason for us to continue processing it.
              </li>
              <li>
                <strong>Right to Restrict Processing:</strong> You can request
                the restriction of processing your personal data under certain
                circumstances.
              </li>
              <li>
                <strong>Right to Data Portability:</strong> You can request to
                receive your personal data in a structured, commonly used, and
                machine-readable format, and to have that data transmitted to
                another controller, where technically feasible.
              </li>
              <li>
                <strong>Right to Object:</strong> You can object to the
                processing of your personal data where we rely on legitimate
                interests as the lawful basis for processing or if we process
                your data for direct marketing purposes.
              </li>
              <li>
                <strong>Right to Withdraw Consent:</strong> Where we rely on
                your consent to process your personal data, you have the right
                to withdraw that consent at any time.
              </li>
            </ul>
            To exercise any of these rights, please contact us at{" "}
            <a
              href="mailto:support@goaldenhour.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@goaldenhour.com
            </a>
            . We will respond to your request in accordance with applicable law.
          </p>
          <h6 className="font-bold">9.4 International Data Transfers</h6>
          <p>
            When we transfer your personal data outside the EU/EEA (such as to
            the United States), we rely on appropriate safeguards, such as
            Standard Contractual Clauses, to ensure your data is protected.
          </p>
          <hr />
          <h5 className="font-bold">
            10. Additional Disclosures for California Residents (CCPA/CPRA)
          </h5>
          <h6 className="font-bold">
            10.1 Categories of Personal Information Collected
          </h6>
          <p>
            Under the California Consumer Privacy Act (CCPA) and the California
            Privacy Rights Act (CPRA), we are required to disclose the
            categories of personal information we collect. We collect the
            following categories:
            <ul>
              <li>Identifiers (e.g., name, email address)</li>
              <li>
                Internet or other electronic network activity information (e.g.,
                device and usage data)
              </li>
              <li>Geolocation data (if you choose to provide it)</li>
              <li>
                Commercial information (e.g., payment data, subscription
                details)
              </li>
            </ul>
          </p>
          <h6 className="font-bold">10.2 California Residents’ Rights</h6>
          <p>
            California residents have the following rights under the CCPA/CPRA
            (subject to certain limitations):
            <ol>
              <li>
                <strong>Right to Know:</strong> You have the right to request
                the specific pieces of personal information we have collected
                about you, as well as the categories of information, the
                purposes for which we collected it, the categories of sources
                from which it was collected, and the categories of third parties
                with whom we shared it.
              </li>
              <li>
                <strong>Right to Delete:</strong> You have the right to request
                the deletion of your personal information that we have
                collected, subject to certain exceptions.
              </li>
              <li>
                <strong>Right to Correct:</strong> You have the right to request
                that we correct inaccurate personal information.
              </li>
              <li>
                <strong>Right to Opt Out of Sale or Sharing:</strong> We do not
                currently sell or share personal information (as those terms are
                defined under the CCPA/CPRA). If we ever decide to sell or share
                personal information, we will provide you with notice and the
                right to opt out.
              </li>
              <li>
                <strong>
                  Right to Limit Use of Sensitive Personal Information:
                </strong>{" "}
                We do not use or disclose sensitive personal information for
                purposes that require a right to limit under the CCPA/CPRA.
              </li>
            </ol>
          </p>
          <h6 className="font-bold">10.3 How to Exercise Your Rights</h6>
          <p>
            To exercise the rights described above, California residents may
            submit a verifiable consumer request by emailing us at
            support@goaldenhour.com or by calling +1 (770)-609-7791 (if
            applicable). We may ask you to verify your identity and confirm that
            you are a California resident before processing your request.
          </p>
          <h6 className="font-bold">10.4 Non-Discrimination</h6>
          <p>
            We will not discriminate against you for exercising any of your
            CCPA/CPRA rights, such as by denying you services, charging
            different prices or rates, or providing a different level or quality
            of service.
          </p>
          <h6 className="font-bold">
            10.5 Do Not Sell or Share My Personal Information
          </h6>
          <p>
            We do not sell or share (as defined under the CCPA/CPRA) your
            personal information. If this changes in the future, we will provide
            an opt-out method on our website and update this Privacy Policy
            accordingly.
          </p>
          <hr />
          <h5 className="font-bold">11. Changes to This Privacy Policy</h5>
          <p>
            We may update this Privacy Policy from time to time. Any updates
            will be posted on this page with a revised effective date. We
            encourage you to review this policy periodically to stay informed
            about how we are protecting your information.
          </p>
          <hr />
          <h5 className="font-bold">12. Contact Us</h5>
          <p>
            If you have questions about this Privacy Policy or how we handle
            your information, please contact us at:
          </p>
          <code>
            Goalden Hour, Inc.
            <br />
            Email: support@goaldenhour.com
            <br />
            Address: 5407 NW Crady Lane, Portland, OR 97229
          </code>
          <p style={{ marginTop: "14px" }}>
            You may also have the right to lodge a complaint with a supervisory
            authority (if you are in the EU/EEA) or a similar regulatory body in
            your jurisdiction, if you believe your rights have been violated.
          </p>
        </div>
      </IonPage>
    );
  }
}

export default Privacy;
