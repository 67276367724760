import { observable, action } from "mobx";
import {
  authCurrentUser,
  getData,
  getHeader,
  postData,
} from "../plugins/auth/auth";
import { IUser } from "../shared/interfaces";
import { USER } from "../config";
import { Auth } from "aws-amplify";
import { notification } from "antd";
import { cookieUtil } from "../utils"; 

class UserStore {
  // ✅ Observables
  @observable proposedUserEmail = "";
  @observable isAccessCodeVerified = false; 
  @observable currentUser: IUser = {
    id: "",
    email: "",
    name: "",
    emailVerified: false,
    idToken: "",
    isAuthenticated: false,
    picture: "",
  };
  @observable showCelebration = false;
  @observable association: any = {
    mentor: {
      email: "",
      name: "",
    },
    team: {
      name: "",
    },
  };
  @observable showAssociation = false;

  baseInit: any = {
    headers: {},
    response: true,
  };

  constructor() {
    this.loadAccessCodeStatus(); 
  }

  
  @action.bound
  public loadAccessCodeStatus() {
    const isVerified = cookieUtil.get("accessCodeVerified");
    this.isAccessCodeVerified = Boolean(isVerified);
  }


  @action.bound
  public setAccessCodeVerified(status: boolean) {
    this.isAccessCodeVerified = status;
    if (status) {
      cookieUtil.set("accessCodeVerified", "true", (5 / (24 * 60))); 
    } else {
      cookieUtil.remove("accessCodeVerified");
    }
  }

  @action.bound
  public async uploadUserProfile(profile: File) {
    let init = this.baseInit;
    let headers = await getHeader();
    if (!init) {
      init = {};
    }
    if (!init.headers) {
      init.headers = {
        Authorization: headers,
      };
    }
    if (headers) {
      init.headers.Authorization = headers;
      init.headers["Accept"] = "*/*";
    }
    const formData = new FormData();
    formData.append("profile", profile);
    init.body = profile;
    const result = await fetch(
      process.env.REACT_APP_API_URL + "/user/upload/avatar",
      {
        method: "POST",
        headers: {
          Authorization: headers,
        },
        body: formData,
      }
    );
    const data = await result.json();
    const awsCurrentUser = await authCurrentUser();
    if (awsCurrentUser === false) {
      return false;
    } else {
      awsCurrentUser.updateAttributes(
        [
          {
            Name: "picture",
            Value: data.link,
          },
        ],
        async (err: any, result: any) => {
          if (err) {
            notification.error({
              message: "Error",
              description: "Error uploading profile picture",
            });
            return false;
          } else {
            let res = await Auth.currentUserInfo(); // solution for refetching user info
            this.currentUser = {
              ...this.currentUser,
              picture: res.attributes.picture,
            };
            notification.success({
              message: "Success",
              description: "Profile picture uploaded successfully",
            });
            return true;
          }
        }
      );
    }
  }

  @action.bound
  public async fetchAssociation() {
    getData(USER, "/user/association", this.baseInit).then((res) => {
      if (res.status === 200) {
        this.showAssociation = true;
        this.association = res.data;
      } else {
        this.showAssociation = false;
        this.association = null;
      }
    });
  }
  // Actions
  @action.bound
  public async getUserInfo() {
    try {
      // Fetch AWS user information
      const awsCurrentUser = await authCurrentUser();
  
      const currentUserInformation = await Auth.currentUserInfo();
     
      if (!awsCurrentUser || !awsCurrentUser.signInUserSession || !awsCurrentUser.signInUserSession.idToken) {
        return false;
      }
  
      const signInSession = awsCurrentUser.signInUserSession;
      const idToken = signInSession.idToken;
      const idTokenPayload = idToken.payload;
  
    
      const identities = idTokenPayload && idTokenPayload.identities;
      const isGoogleProvider =
        identities &&
        Array.isArray(identities) &&
        identities.some(identity => identity.providerName === "Google");
  
      
      if (isGoogleProvider) {
        this.currentUser = {
          id: idTokenPayload.sub || "",
          email: idTokenPayload.email || "",
          name: idTokenPayload.name || "",
          emailVerified: !!idTokenPayload.email_verified,
          idToken: idToken.jwtToken || "",
          picture: idTokenPayload.picture || "",
          isAuthenticated: true,
        };
      } else {
        this.currentUser = {
          id: idTokenPayload.sub || "",
          email: currentUserInformation.attributes ? currentUserInformation.attributes.email : "",
          name: currentUserInformation.attributes ? currentUserInformation.attributes.name : "",
          emailVerified: !!idTokenPayload.email_verified,
          idToken: idToken.jwtToken || "",
          picture: idTokenPayload.picture || "",
          isAuthenticated: true,
        };
      }
  
      return true;
    } catch (error) {
      console.error("Error fetching user info:", error);
      return false;
    }
  }
  

  @action.bound
  public resetUser() {
    this.currentUser = {
      id: "",
      email: "",
      name: "",
      emailVerified: false,
      idToken: "",
      isAuthenticated: false,
      picture: "",
    };
    this.setAccessCodeVerified(false); 
  }

  @action.bound
  public async updateUserName(name: string) {
    // Check AWS for user
    const awsCurrentUser = await authCurrentUser();
    if (awsCurrentUser === false) {
      return false;
    } else {
      awsCurrentUser.updateAttributes(
        [
          {
            Name: "name",
            Value: name,
          },
        ],
        async (err: any, result: any) => {
          if (err) {
            return false;
          } else {
            let res = await Auth.currentUserInfo(); // solution for refetching user info
            this.currentUser = {
              ...this.currentUser,
              name: res.attributes.name,
            };
            return true;
          }
        }
      );
    }
  }

  public isStudent() {
    const { email } = this.currentUser;
    return email.toLowerCase().includes(".edu"); // returns bool
  }
}

export default new UserStore();
