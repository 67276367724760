import React from "react";
import { IonPage } from "@ionic/react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Input, Button, Alert } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import "./AccessCode.scss";
import userStore from "../../stores/userStore";



interface IProps extends RouteComponentProps {}

@observer
class AccessCode extends React.Component<IProps> {
  @observable public accessCode = "";
  @observable public email = "";
  @observable public error: null | string = null;
  @observable public loading = false;
  @observable public isEditing = false;


  @action
  public verifyAccessCode = async (code: string, email: string) => {
    try {
      this.loading = true;
      const response = await fetch(`${process.env.REACT_APP_API_URL}/access-code/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, email }),
      });

      const result = await response.json();
      console.log("API Response:", result);

      if (response.ok) {
        return true;
      } else {
        this.error = result.message || "Invalid access code. Please try again.";
        return false;
      }
    } catch (error) {
      console.error("API Error:", error);
      this.error = "Something went wrong. Please try again later.";
      return false;
    } finally {
      this.loading = false;
    }
  };

  
  @action
  public handleSubmit = async () => {
    if (!this.email || !this.accessCode) {
      this.error = "Both Email and Access Code are required.";
      return;
    }

    const isCodeValid: boolean = await this.verifyAccessCode(
      this.accessCode.trim(),
      this.email.trim()
    );
    isCodeValid && userStore.setAccessCodeVerified(true)

    this.isEditing = false
    if (isCodeValid) {
        this.props.history.push(`/signup?email=${encodeURIComponent(this.email)}`);
    }
  };


  @action
  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.isEditing = true;
    if (name === "accessCode") {
      this.accessCode = value.toUpperCase().replace(/[^A-Z0-9]/g, ""); 
    } else if (name === "email") {
      this.email = value;
    }
  };

  render() {
    return (
      <IonPage className="ion-padding access-code-page">
        <div className="center-text form-container">
          <h1 className="access-code-header">Enter Access Code</h1>
          <p className="access-code-subheader">
            Please enter your email and access code to proceed.
          </p>

          {this.error && !this.isEditing && <Alert message={this.error} type="error" showIcon />}

        
          <div className="input-container">
            <Input
              className="input-access-code"
              id="email"
              name="email"
              value={this.email}
              prefix={<MailOutlined />}
              placeholder="Enter your Email"
              onChange={this.handleChange}
              data-cy="email"
            />
          </div>


          <div className="input-container">
            <Input
              className="input-access-code"
              value={this.accessCode}
              id="accessCode"
              name="accessCode"
              prefix={<LockOutlined />}
              placeholder="Enter Access Code"
              maxLength={4}
              onChange={this.handleChange}
              data-cy="accessCode"
            />
          </div>

          <div className="button-container">
            <Button
              type="primary"
              className="form-submit-button"
              onClick={this.handleSubmit}
              loading={this.loading}
              data-cy="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </IonPage>
    );
  }
}

export default withRouter(AccessCode);
