// Packages
import React from "react";
import { IonPage } from "@ionic/react";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import {
  EyeFilled,
  EyeInvisibleFilled,
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form, notification } from "antd";
import "@ant-design/compatible/assets/index.css";
// Styles
import { Input, Button, Alert } from "antd";
// Plugin Functions
import { authSignUp, postData } from "../../plugins/auth/auth";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { GoogleSVG } from "../../components/svgComponents/GoogleSVG";
import {  isEmail, validatePassword } from "../../utils";
interface IProps extends RouteComponentProps {
  userStore: any;
  match: any;
}

@inject("userStore")
@observer
class SignUp extends React.Component<IProps> {
  
  @observable public name = "";
  @observable public email = decodeURIComponent(new URLSearchParams(this.props.location.search).get('email') || "") || "";
  @observable public password = "";
  @observable public error = null;
  @observable public loading = false;
  @observable public loadingGoogle = false;
  @observable public signedUp = false;
  @observable public userPreFilledInfo: any = "";
  @observable public urlParams: any = "";
  @observable public baseInit: any = {
    headers: {},
    response: true,
  };
  @observable public showPassword: boolean = false;
  @observable public lockEmail: boolean = false;


  public componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteParam = urlParams.get("invite");

    if (inviteParam) {
      try {
        const decodedInvite = JSON.parse(atob(decodeURIComponent(inviteParam)));
        this.userPreFilledInfo = decodedInvite;
        document.cookie = `invitationId=${encodeURIComponent(
          inviteParam
        )}; path=/;`;
      } catch (error) {
        console.error("Invalid invite parameter:", error);
      }
    } else {
      const cookies = document.cookie.split("; ");
      const invitationIdCookie = cookies.find((row) =>
        row.startsWith("invitationId=")
      );

      if (invitationIdCookie) {
        const inviteFromCookie = invitationIdCookie.split("=")[1];
        try {
          const decodedInvite = JSON.parse(
            atob(decodeURIComponent(inviteFromCookie))
          );

          
          this.userPreFilledInfo = decodedInvite;
        } catch (error) {
          console.error("Error decoding invite cookie:", error);
        }
      }
    }


    if (this.userPreFilledInfo && this.userPreFilledInfo.name) {
      this.name = this.userPreFilledInfo.name;
    } else {
      this.name = "";
    }
    
    if (this.userPreFilledInfo && this.userPreFilledInfo.email) {
      this.email = this.userPreFilledInfo.email;
    } else {
      this.email = "";
    }
    // this.lockEmail = this.userPreFilledInfo.email ? true : false;
  }

  
  


  public handleSubmit = async (event: React.FormEvent) => {
    //event.preventDefault();
    this.signUp();
    await postData(
      "invitation",
      `/invitation/${this.userPreFilledInfo.invitationId}`,
      this.baseInit
    );
    this.loading = false;
  };
  public handleSubmitGoogle = async (event: React.FormEvent) => {
    //event.preventDefault();
    this.loadingGoogle = true;
    console.log("signing in with google");
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
    await postData(
      "invitation",
      `/invitation/${this.userPreFilledInfo.invitationId}`,
      this.baseInit
    );
    this.loadingGoogle = false;
  };

  signUp = async () => {
    this.props.userStore.proposedUserEmail = this.email;
    let resError = null;
    if (this.name === "" || this.email === "" || this.password === "") {
      return notification.error({
        message: "Error",
        description: "Name, Email, and Password cannot be empty",
      });
    }

  // Validate email
  if (!isEmail(this.email)) {
    notification.error({
      message: "Error",
      description: "Please enter a valid email"
    });
    this.loading = false;
    return;
  }

   // Validate password
   const validPassword = validatePassword(this.password);
   if (!validPassword.valid) {
     notification.error({message: "Error",description: validPassword.message});
     this.loading = false;
     return;
   }
    
   
      resError = await authSignUp(
        this.props,
        this.name,
        this.email.trim().toLowerCase(),
        this.password
      );
    
    if (!resError) {
      this.loading = false;
      console.log("No error returned from authSignUp");
    }
    // If an error is returned from auth, error is assigned to observable
    this.error = resError;
    this.loading = false;
    if (!this.error) {
      //only reroute if no error is returned
      this.props.history.push({
        pathname: "/confirm-email",
        hash: `${this.email}`,
      });
    }
  };

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    switch (target.name) {
      case "name":
        this.name = target.value;
        break;
      case "email":
        this.email = target.value;
        break;
      case "password":
        this.password = target.value;
        break;
      default:
        console.log("Unknown form value change");
    }
  };

  public renderGoogleButton() {
    if (this.loadingGoogle) {
      return (
        <Button type="default" className="google-button my-1">
          <LoadingOutlined />
        </Button>
      );
    } else {
      return (
        <Button
          type="default"
          className="google-button my-1"
          onClick={this.handleSubmitGoogle}
        >
          <GoogleSVG /> Sign up with Google
        </Button>
      );
    }
  }

  public renderSubmitBtn() {
    if (this.loading) {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button sign-in-button"
        >
          <LoadingOutlined />
        </Button>
      );
    } else {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button sign-in-button"
        >
          Sign Up
        </Button>
      );
    }
  }

  render() {
    return (
      <IonPage className="ion-padding">
        <div className="center-text form-container m-y-auto">
          <h1 className="sign-in-header">Welcome to Goalden Hour</h1>
          <p className="sign-in-subheader">
            Simple goal based tool for everyone
          </p>

          {this.error ? (
            <Alert
              message={
                this.error === "Username cannot be empty"
                  ? "Email cannot be empty"
                  : this.error
              }
              type="error"
              showIcon
            />
          ) : (
            ""
          )}
         
            {/* <div className="login-form">{this.renderGoogleButton()}</div> */}
          <Form onFinish={this.handleSubmit} className="login-form">
            <Form.Item className="form-item">
              <label htmlFor="name" id="nameLabel" className="label-sign-in">
                Name
              </label>
              <Input
                className="input-sign-in"
                id="name"
                name="name"
                prefix={<UserOutlined />}
                placeholder="Name"
                value={this.name}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item className="form-item">
              <label htmlFor="email" id="emailLabel" className="label-sign-in">
                Email
              </label>
              <Input
                className="input-sign-in"
                id="email"
                name="email"
                prefix={<MailOutlined />}
                placeholder="Email"
                value={this.email}
                onChange={this.handleChange}
                disabled={this.lockEmail}
              />
            </Form.Item>
            <Form.Item className="form-item">
              <label
                htmlFor="password"
                id="passwordLabel"
                className="label-sign-in"
              >
                Password
              </label>
              <div className="flexBoxRow input-sign-in border">
                <Input
                  className="no-border"
                  id="password"
                  name="password"
                  prefix={<LockOutlined />}
                  type={this.showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={this.password}
                  onChange={this.handleChange}
                />
                <div
                  onClick={() => {
                    this.showPassword = !this.showPassword;
                  }}
                  className="kebabMenu padding-around"
                >
                  {this.showPassword ? <EyeFilled /> : <EyeInvisibleFilled />}
                </div>
              </div>
              <Link to="/forgot-password">Forgot password?</Link>
            </Form.Item>
            <div className="m-x-auto">{this.renderSubmitBtn()}</div>
          </Form>
          <br />
          {/* <Alert
            message={
              "💡 Sign up with a '.edu' email address for student-specific content!"
            }
            type="info"
          /> */}

          <div className="auth-options center-flex">
            <Link to="/signin">
              Existing user? <span>Sign in</span>
            </Link>
          </div>

          <p className="terms-optin">
            By signing up,
            <br />
            You agree to our &nbsp;
            <Link to="/terms-of-use" className="primary-link">
              terms
            </Link>
            &nbsp;and&nbsp;
            <Link to="/privacy-policy" className="primary-link">
              privacy &nbsp;policy.
            </Link>
          </p>
        </div>
      </IonPage>
    );
  }
}

export default withRouter(SignUp);
