import Cookies from "js-cookie";


export const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};


export const cookieUtil = {
    set: (key: string, value: any, expiresInDays = 1) => {
      Cookies.set(key, JSON.stringify(value), { expires: expiresInDays, path: "/" });
    },
  
    get: (key: string): any | null => {
      const value = Cookies.get(key);
      return value ? JSON.parse(value) : null;
    },
  
    remove: (key: string) => {
      Cookies.remove(key, { path: "/" });
    },
  };


  const emailRegex =
  /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*)(?:\+([a-zA-Z0-9]+))?@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

export const   isEmail = (email: string) => {
  return emailRegex.test(email);
}


export const  validatePassword = (password: string) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':",./<>?|\\ ]/.test(password);
    const minLength = 8; // Adjust minimum length as needed
  
    if (password.length < minLength) {
        return { valid: false, message: "Please make sure password is at least 8 characters long" };
    }
  
    const missingCriteria = [];
  
    if (!hasUppercase) missingCriteria.push("an uppercase letter");
    if (!hasNumber) missingCriteria.push("a number");
    if (!hasSpecialChar) missingCriteria.push("a special character");
  
    if (missingCriteria.length > 0) {
        return { valid: false, message: `Please make sure password contains ${missingCriteria.join(", ")}` };
    }
  
    return { valid: true, message: "" };
  }

  declare let nativeFunctions: any;

  export const loginApp = () =>  {
    try {
      var userAgent = navigator.userAgent.toLowerCase();
        // Check if the user agent contains the string "canvas"
        if (userAgent.indexOf('canvas') > -1 && nativeFunctions) {
            nativeFunctions.login();
        }
    } catch (ex) {
        console.log((ex as any).message);
    }
}