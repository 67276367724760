// Packages
import React from 'react';
import { IonPage } from '@ionic/react';
// Type Definitions
import { RouteComponentProps } from 'react-router-dom';

class Terms extends React.Component<RouteComponentProps> {
  render() {
    return (
      <IonPage className="ion-padding scroll-page">
        <div className="longform-text">
          <h1 className="center-text bold-text">Terms of Use</h1>
          <p><strong>Last Updated: January 28, 2025</strong></p>
          
          <p>Goalden Hour (the “Website,” “we,” “us,” or “our”) offers productivity and personal development tools, which include the Goalden Hour app and Goal Post platform (collectively, the “Services”). The Services may be used to discover new self-improvement activities, manage tasks and routines, and, through Goal Post, engage in goal-setting or coaching interactions. These Terms of Use (the “Terms” or the “Agreement”), together with our Privacy Policy (and any other documents they expressly incorporate), set forth the terms and conditions upon which you may access and use the Services, including any content and functionality offered through them.</p>
          
          <p>By accessing or using the Services, you agree to be bound by these Terms of Use. If you do not agree with any part of these Terms, you must not access or use our Services.</p>

          <h2 className="bold-text">1. Changes to These Terms</h2>
          <p>We reserve the right to modify or replace these Terms of Use and/or to change, suspend, or discontinue any part of the Services at any time in our sole discretion. We may also impose limits on certain features or restrict your access to parts or all of the Services without notice or liability.</p>
          <p>We may provide notice of changes to these Terms by posting updated text on our Website, within our applications, or through other electronic means. Your continued use of the Services following the posting of any change or modification will constitute your acceptance of the modified Terms of Use. It is your responsibility to review these Terms of Use periodically for updates.</p>

          <h2 className="bold-text">2. Use of the Service</h2>
          <h3 className="left-align-text">2.1 General</h3>
          <p>Subject to these Terms, Goalden Hour grants you a non-exclusive, non-transferable right to access and use the Services (including the Goal Post platform) for your personal, non-commercial use.</p>
          <p>You agree that you will not:</p>
          <ul>
            <li>Reverse-engineer, decompile, disassemble, translate, or otherwise attempt to access or derive the source code of any aspect of the Services.</li>
            <li>Copy, reproduce, alter, create derivative works from, or otherwise modify the Services, including any technology, software, or content.</li>
            <li>Lease, loan, sublicense, distribute, or otherwise provide others access to the Services or any part thereof.</li>
          </ul>

          <h3 className="left-align-text">2.2 Purpose of the Services</h3>
          <p>The Services are designed to assist individuals in achieving goals through healthy habits, productive routines, and behavior changes. The Goal Post platform may include functionalities for coaching or team-based goal-tracking, where you can share and collaborate on goals with coaches, mentors, or fellow users.</p>
          <p><strong>Disclaimer:</strong> While we aim to support you in achieving your goals, individual results vary, and we cannot guarantee specific outcomes. Goalden Hour does not provide medical services or clinical diagnoses.</p>

          <h3 className="left-align-text">2.3 Medical Disclaimer</h3>
          <p>The Services do not include the provision of medical care, diagnosis, or treatment. Goalden Hour is not a licensed medical care provider. You should not change your treatment or medication without consulting your healthcare provider. Any suggestions or content provided within our Services are supplemental and not a substitute for professional medical advice.</p>

          <h2 className="bold-text">3. Registration</h2>
          <p>You are required to register for the Services and provide certain information about yourself. All information we collect is subject to our Privacy Policy.</p>
          <ul>
            <li>You are responsible for maintaining the confidentiality of your account and password.</li>
            <li>You agree to notify us immediately of any unauthorized access or use of your account.</li>
            <li>You are responsible for all activities that occur under your user account.</li>
          </ul>

          <h2 className="bold-text">4. Term and Termination</h2>
          <p>These Terms remain in full force and effect while you use the Services.</p>
          <p>We may suspend or terminate your access to the Services if you:</p>
          <ul>
            <li>Breach any term of this Agreement.</li>
            <li>Engage in any conduct that we determine, in our sole discretion, may harm Goalden Hour or its reputation.</li>
          </ul>

          <h2 className="bold-text">5. Intellectual Property Ownership</h2>
          <p>All rights, title, and interest in and to the Services, including any technology, software, content, marks, or related intellectual property, belong solely to Goalden Hour or its licensors.</p>

          <h2 className="bold-text">6. Confidentiality</h2>
          <p>We will use and protect your personal information according to our Privacy Policy. You agree not to disclose any confidential information of Goalden Hour without prior written consent.</p>

          <h2 className="bold-text">7. Third-Party Links and Technology</h2>
          <p>The Services may provide access to or integrate with third-party technology, services, or links. Goalden Hour does not endorse or warrant any Third-Party Technology and is not responsible for any harm arising from your use of them.</p>

          <h2 className="bold-text">8. Indemnity</h2>
          <p>You agree to defend, indemnify, and hold harmless Goalden Hour, its officers, directors, employees, agents, licensors, and suppliers from and against any claims, actions, or demands that result from your use of the Services.</p>

          <h2 className="bold-text">9. Disclaimer of Warranties</h2>
          <p>The Services are provided on an "as is" and "as available" basis. Goalden Hour does not guarantee uninterrupted, error-free service or specific results.</p>

          <h2 className="bold-text">10. Limitation of Liability</h2>
          <p>Goalden Hour and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or related to your use of the Services.</p>

          <h2 className="bold-text">11. Contact Information</h2>
          <p>If you have any questions or concerns regarding these Terms or the Services, please contact us at:</p>
          <p><strong>Goalden Hour, Inc.</strong></p>
          <p>Email: support@goaldenhour.com</p>
          <p>Address: 5407 NW Crady Lane, Portland, OR 97229</p>

          <p>By using any part of the Goalden Hour Services (including Goal Post), you confirm that you have read, understood, and agree to these Terms of Use.</p>
        </div>
      </IonPage>
    );
  }
};

export default Terms;
