import React from "react";
import { Route, Redirect, RouteProps, RouteComponentProps, withRouter } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { observable } from "mobx";
import { getAccessTokenFromLocalStorage } from "../../plugins/localStorage";

interface EmptyLayoutProps extends RouteProps {
  component: any;
  history: RouteComponentProps["history"];
  location: RouteComponentProps["location"];
  match: RouteComponentProps["match"];
  staticContext?: RouteComponentProps["staticContext"];
}

class EmptyLayout extends React.Component<EmptyLayoutProps> {
  @observable public showHeader = false;

  public getToken = () => {
    return getAccessTokenFromLocalStorage();
  };

  render() {
    const { component: Component, ...rest } = this.props;
    const isAuthPage =
    rest.path === "/signin" ||
    rest.path === "/signup" ||
    rest.path === "/signup/:invite" ||
    rest.path === "/forgot-password" ||
    rest.path === "/reset-password" ||
    rest.path === "/confirm-email";

  this.showHeader = !isAuthPage;

  let route = "/goals";
  if (new URLSearchParams(this.props.location.search).get("invite")) {
    route += "?invite=" + new URLSearchParams(this.props.location.search).get("invite");
  }
    return (
      <Route
        {...rest}
        render={(routeProps) =>
          this.getToken() && !this.showHeader && rest.path !== "/reset-password" ? (
            <Redirect to={route} />
          ) : (
            <>
            {this.showHeader && (
              <Header />
            )}
              <Component {...routeProps} />
            {this.showHeader && (
              <Footer />
            )}
            </>
          )
        }
      />
    );
  }
}

export default withRouter(EmptyLayout);